<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_defectiveDiscardedDetail" />
      <!-- 不良・廃棄内容詳細画面 -->
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-row-exeBtn">
              <!-- 品番-->
              <div class="serch-textbox-itemCd first-search-item" style="float: left; width: 250px">
                <v-text-field
                  dense
                  v-model="headerProductCode"
                  :label="$t('label.lbl_productCode')"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                ></v-text-field>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 付帯品番 -->
              <div class="serch-textbox-itemCd" style="float: left; width: 250px">
                <v-text-field
                  dense
                  v-model="headerProductCodeSub"
                  :label="$t('label.lbl_accessoryProduct')"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                ></v-text-field>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 品名 -->
              <div class="serch-textbox-itemName" style="float: left; width: 250px">
                <v-text-field
                  dense
                  v-model="headerProductName"
                  :label="$t('label.lbl_productCodeName')"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                ></v-text-field>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 品質区分 -->
              <div class="serch-textbox-qualityDiv" style="float: left; width: 125px">
                <v-text-field
                  dense
                  v-model="headerQualityDiv"
                  :label="$t('label.lbl_qualityDiv')"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                ></v-text-field>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- ロット -->
              <div class="serch-textbox-lotNo" style="float: left; width: 230px">
                <v-text-field
                  dense
                  v-model="headerLotNo"
                  :label="$t('label.lbl_lot')"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                ></v-text-field>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- S/N -->
              <div class="serch-textbox-serialNo" style="float: left; width: 250px">
                <v-text-field
                  dense
                  v-model="headerSerialNo"
                  :label="$t('label.lbl_serial')"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                ></v-text-field>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 数量 -->
              <div class="serch-textbox-quantity" style="float: left; width: 125px">
                <v-text-field
                  dense
                  v-model="headerQuantity"
                  :label="$t('label.lbl_quantity')"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                ></v-text-field>
              </div>
              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
              <!-- 管理No -->
              <div class="serch-textbox-managementNo" style="float: left; width: 230px">
                <v-text-field
                  dense
                  v-model="headerManagementNo"
                  :label="$t('label.lbl_managementNo')"
                  class="txt-single"
                  persistent-hint
                  :readonly="true"
                ></v-text-field>
              </div>
            </div>
          </v-row>
          <v-row>
            <!-- 期限日-->
            <div class="serch-textbox-itemCd first-search-item" style="float: left; width: 250px">
              <v-text-field
                dense
                v-model="headerDueDate"
                :label="$t('label.lbl_dueDate')"
                class="txt-single"
                persistent-hint
                :readonly="true"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 付属キー１ -->
            <div class="serch-textbox-itemCd" style="float: left; width: 250px">
              <v-text-field
                dense
                v-model="headerLotSubkey1"
                :label="$t('label.lbl_lotSubkey1')"
                class="txt-single"
                persistent-hint
                :readonly="true"
              ></v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 付属キー２ -->
            <div class="serch-textbox-itemName" style="float: left; width: 250px">
              <v-text-field
                dense
                v-model="headerLotSubkey2"
                :label="$t('label.lbl_lotSubkey2')"
                class="txt-single"
                persistent-hint
                :readonly="true"
              ></v-text-field>
            </div>
          </v-row>
          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--戻るボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン-->
                <v-btn color="primary" class="api-btn" @click="viewConfirmDialog('btn_back')">
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>
              <div style="float: right">
                <!-- 行追加ボタン-->
                <v-btn color="primary" class="api-btn" @click="addItem()">
                  {{ $t("btn.btn_add") }}
                </v-btn>
                <!-- 登録ボタン -->
                <v-btn
                  color="primary"
                  id="btn-content-confirm"
                  class="other-btn"
                  @click="viewConfirmDialog('btn_insert')"
                  >{{ $t("btn.btn_insert") }}</v-btn
                >
              </div>
            </div>
          </v-row>
        </v-container>

        <v-form ref="inputList" lazy-validation>
          <!-- データテーブル -->
          <v-container fluid>
            <v-data-table
              id="listData"
              fixed-header
              :headers="headerItems"
              :items="inputList"
              :page.sync="page"
              :server-items-length="itemsPerPage"
              disable-filtering
              :hide-default-footer="true"
              height="660px"
            >
              <!-- 検査Sid -->
              <template v-slot:[`item.inspectionSid`]="{ item }">
                <div class="align-left">{{ item.inspectionSid }}</div>
              </template>
              <!-- カレンダー -->
              <!-- 検査実績日-->
              <template v-slot:[`item.dateOfInspection`]="{ item }">
                <!-- <v-form lazy-validation :ref="'dateOfInspection' + index"> -->
                <td style="display: flex">
                  <div class="date-style">
                    <v-text-field
                      outlined
                      dense
                      v-model="item.dateOfInspection"
                      @change="changeDate('dateOfInspection', item), setInActDate(item)"
                      :rules="[
                        rules.yyyymmdd,
                        (!item.contentsOfInspectionItems &&
                          !item.inspector &&
                          !item.inputDetail &&
                          !item.locationOfDiscovery &&
                          !item.dateEntered &&
                          !item.enteryPerson) ||
                          rules.required,
                      ]"
                      class="txt-single date-style text-box"
                      :error-messages="item.alertSupplierMessage"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <div class="calender-btn-area" style="padding-top: 10px">
                    <v-menu
                      v-model="item.dateOfInspectionMenu"
                      :close-on-content-click="false"
                      :nudge-right="-100"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      class="date-calendar"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text x-small>
                          <v-icon>mdi-calendar</v-icon>
                        </v-btn>
                      </template>
                      <v-date-picker
                        v-model="item.dateOfInspectionCal"
                        @input="item.dateOfInspectionMenu = false"
                        @change="
                          (item.dateOfInspection = formatDate(item.dateOfInspectionCal)),
                            setInActDate(item)
                        "
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </td>
                <!-- </v-form> -->
              </template>
              <!-- 工程 -->
              <template v-slot:[`item.opeHist`]="{ item }">
                <div id="table-opeHist">
                  <v-autocomplete
                    v-model="item.opeHist"
                    :items="opeHistList"
                    :hint="setOpeHist(item)"
                    item-text="text"
                    dense
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                    <!-- * -->
                  </v-autocomplete>
                </div>
              </template>
              <!-- 検査担当者 -->
              <template v-slot:[`item.inspector`]="{ item }">
                <!-- <v-form lazy-validation :ref="'inspector' + index"> -->
                <div id="table-opeHist">
                  <v-autocomplete
                    v-model="item.inspector"
                    :items="inspectorList"
                    :hint="setUser(item)"
                    :rules="[
                      (!item.contentsOfInspectionItems &&
                        !item.dateOfInspection &&
                        !item.inputDetail &&
                        !item.locationOfDiscovery &&
                        !item.dateEntered &&
                        !item.enteryPerson) ||
                        rules.required,
                    ]"
                    item-text="text"
                    dense
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                    <!-- * -->
                  </v-autocomplete>
                </div>
                <!-- </v-form> -->
              </template>
              <!-- 検査項目内容 -->
              <template v-slot:[`item.contentsOfInspectionItems`]="{ item }">
                <!-- <v-form lazy-validation :ref="'contentsOfInspectionItems' + index"> -->
                <div id="table-opeHist">
                  <v-autocomplete
                    v-model="item.contentsOfInspectionItems"
                    :items="contentsOfInspectionItemsList"
                    :hint="setContentsOfInspectionItems(item)"
                    :rules="[
                      (!item.inspector &&
                        !item.dateOfInspection &&
                        !item.inputDetail &&
                        !item.locationOfDiscovery &&
                        !item.dateEntered &&
                        !item.enteryPerson) ||
                        rules.required,
                    ]"
                    item-text="text"
                    dense
                    @change="changeContentsOfInspectionItems(item)"
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                    <!-- * -->
                  </v-autocomplete>
                </div>
                <!-- </v-form> -->
              </template>
              <!-- 発見場所 -->
              <template v-slot:[`item.locationOfDiscovery`]="{ item }">
                <!-- <v-form lazy-validation :ref="'locationOfDiscovery' + index"> -->
                <v-text-field
                  v-model="item.locationOfDiscovery"
                  outlined
                  dense
                  :rules="[
                    (!item.inspector &&
                      !item.dateOfInspection &&
                      !item.inputDetail &&
                      !item.contentsOfInspectionItems &&
                      !item.dateEntered &&
                      !item.enteryPerson) ||
                      rules.required,
                  ]"
                  class="text-box"
                  maxlength="200"
                  clear-icon="mdi-close-circle"
                  clearable
                >
                </v-text-field>
                <!-- </v-form> -->
              </template>
              <!-- 路線業者 -->
              <template v-slot:[`item.routeOperator`]="{ item }">
                <div id="table-routeOperator">
                  <v-autocomplete
                    v-model="item.routeOperator"
                    :items="deliveryTraderList"
                    :hint="setDeliveryTraderName(item)"
                    item-text="text"
                    dense
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                    <!-- * -->
                  </v-autocomplete>
                </div>
              </template>
              <!-- 便名 -->
              <template v-slot:[`item.flightNumber`]="{ item }">
                <v-text-field
                  v-model="item.flightNumber"
                  maxlength="20"
                  outlined
                  dense
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                >
                </v-text-field>
              </template>
              <!-- カレンダー -->
              <!-- 記入日-->
              <template v-slot:[`item.dateEntered`]="{ item }">
                <!-- <v-form lazy-validation :ref="'dateEntered' + index"> -->
                <td style="display: flex">
                  <div class="date-style">
                    <v-text-field
                      outlined
                      dense
                      v-model="item.dateEntered"
                      @change="changeDate('dateEntered', item)"
                      :rules="[
                        rules.yyyymmdd,
                        (!item.inspector &&
                          !item.dateOfInspection &&
                          !item.inputDetail &&
                          !item.contentsOfInspectionItems &&
                          !item.enteryPerson &&
                          !item.locationOfDiscovery) ||
                          rules.required,
                      ]"
                      class="txt-single date-style text-box"
                      clear-icon="mdi-close-circle"
                      clearable
                    ></v-text-field>
                  </div>
                  <div class="calender-btn-area" style="padding-top: 10px">
                    <v-menu
                      v-model="item.dateEnteredMenu"
                      :close-on-content-click="false"
                      :nudge-right="-100"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      class="date-calendar"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" text x-small>
                          <v-icon>mdi-calendar</v-icon>
                        </v-btn>
                      </template>
                      <v-date-picker
                        v-model="item.dateEnteredCal"
                        @input="item.dateEnteredMenu = false"
                        @change="item.dateEntered = formatDate(item.dateEnteredCal)"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </td>
                <!-- </v-form> -->
              </template>
              <!-- 記入者 -->
              <template v-slot:[`item.enteryPerson`]="{ item }">
                <!-- <v-form lazy-validation :ref="'enteryPerson' + index"> -->
                <div id="table-enteryPerson">
                  <v-autocomplete
                    v-model="item.enteryPerson"
                    :items="enteryPersonList"
                    :hint="setEnteryPerson(item)"
                    :rules="[
                      (!item.inspector &&
                        !item.dateOfInspection &&
                        !item.inputDetail &&
                        !item.contentsOfInspectionItems &&
                        !item.dateEntered &&
                        !item.locationOfDiscovery) ||
                        rules.required,
                    ]"
                    item-text="text"
                    dense
                  >
                    <!-- アイテム一覧の表示 -->
                    <template slot="item" slot-scope="data">
                      <span class="test">
                        {{ data.item.text }}
                      </span>
                    </template>
                    <!-- * -->
                  </v-autocomplete>
                </div>
                <!-- </v-form> -->
              </template>
              <template v-slot:[`item.inputDetail`]="{ item }">
                <v-btn small @click="openDetailInputDialog(item)" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <span v-if="item.inputDetail !== null && 5 < item.inputDetail.length" class="test">
                  {{ item.inputDetail.substr(0, 5) }}
                </span>
                <span v-else class="test">
                  {{ item.inputDetail }}
                </span>
              </template>
              <template v-slot:[`item.upload`]="{ item, index }">
                <v-btn small @click="openFileUploadDialog(item, index)" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.reportOutput`]="{ item }">
                <v-btn small @click="reportOutput(item)" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.download`]="{ item }">
                <v-btn small @click="openFileDownloadDialog(item)" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                <v-btn small @click="openDeleteDialog(item)" text>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-form>

        <v-navigation-drawer absolute temporary v-model="openMenu">
          <sideMenu></sideMenu>
        </v-navigation-drawer>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :firstPageFlag="infoDialog.firstPageFlag"
          :defectiveDiscardedDetailPageFlag="infoDialog.defectiveDiscardedDetailPageFlag"
          :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
          :okAction="infoDialog.okAction"
        />
        <v-dialog v-model="updateDialogMessage" :max-width="800">
          <!-- 警告ダイアログ -->
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ backMessage }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="updateOk"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialogMessage" :max-width="800">
          <!-- 警告ダイアログ -->
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ deleteMessage }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="toDeleteItem"> OK </v-btn>
              <v-btn @click="cancel"> キャンセル </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <!-- 詳細入力モーダル -->
      <v-dialog v-model="isOpenDetailInputDialog" :max-width="1300" persistent>
        <detailInputDialog
          :isOpenDetailDialog.sync="isOpenDetailInputDialog"
          :editItem="editItem"
          v-on:child-event="parentMethod"
        />
      </v-dialog>
      <!-- ファイルダウンロードモーダル -->
      <v-dialog v-model="isOpenFileDownloadDialog" :max-width="1300" persistent>
        <fileDownloadDialog
          :isOpenFileDownloadDialog.sync="isOpenFileDownloadDialog"
          :editItem="editItem"
          v-on:child-event="parentMethod"
        />
      </v-dialog>
      <!-- ファイルアップロードモーダル -->
      <v-dialog v-model="isOpenFileUploadDialog" :max-width="1300" persistent>
        <fileUploadDialog
          :isOpenFileUploadDialog.sync="isOpenFileUploadDialog"
          :editItem="editItem"
          :itemStandardSid="itemStandardSid"
          :itemManageNo="headerManagementNo"
          :itemManageNoOrg="itemManageNoOrg"
          :clientSid="suppliersSelected"
          :qualityDiv="qualityDiv"
          :inActDate="inActDate"
          :inListSubSid="inListSubSid"
          :outListSubSid="outListSubSid"
          v-on:child-event="parentMethod"
        />
      </v-dialog>
      <!-- 廃棄実績削除モーダル -->
      <v-dialog v-model="isOpenDeleteDialog" :max-width="800" persistent>
        <deleteDialog
          :isOpenDeleteDialog.sync="isOpenDeleteDialog"
          :editItem="editItem"
          v-on:child-event="parentMethod"
        />
      </v-dialog>
    </v-sheet>
    <ConfirmDialog
      :isShow.sync="ConfirmDialog.isOpen"
      :message="ConfirmDialog.message"
      :okAction="ConfirmDialog.okAction"
      :redMessage="ConfirmDialog.redMessage"
      :screenFlag="ConfirmDialog.screenFlag"
      :changeFlag="ConfirmDialog.changeFlag"
    />
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import detailInputDialog from "./DefectiveDiscardedDetailInputDialog";
import fileDownloadDialog from "./DefectiveDiscardedFileDownloadDialog";
import fileUploadDialog from "./DefectiveDiscardedFileUploadDialog";
import deleteDialog from "./DefectiveDiscardedDeleteDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.MENU_ID.P_BIL_CONTRACT_LIST,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    detailInputDialog,
    fileDownloadDialog,
    fileUploadDialog,
    deleteDialog,
    ConfirmDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    singleSelect: false,
    pListPopup: false,
    alertMessage: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 取引先
    suppliersSelected: "",
    // メニュー
    openMenu: null,
    returnURL: "",
    // 入荷予定日付カレンダー
    dateMenu: false,
    dateMenu2: false,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      defectiveDiscardedDetailPageFlag: false,
      outsideClickNotCloseFlg: false,
      firstPageFlag: false,
      okAction: () => {},
    },
    sortItem: "",
    // 総件数
    totalCount: 0,
    // ダイアログ
    updateDialogMessage: false,
    deleteDialogMessage: false,
    // ダイアログメッセージ
    backMessage: "",
    deleteMessage: "",
    // 削除リスト
    deleteItem: [],
    // 編集された項目のインデックス
    editedIndex: -1,
    // エラーカウント
    errorChecks: 0,
    // 削除用変数
    deleteData: {},
    // ヘッダ
    headerItems: [
      // 検査SID
      {
        text: i18n.tc("label.lbl_inspectionSid"),
        value: "inspectionSid",
        width: "150px",
        align: "left",
        sortable: false,
      },
      // 検査実績日
      {
        text: i18n.tc("label.lbl_dateOfInspection"),
        value: "dateOfInspection",
        width: "200px",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 工程
      {
        text: i18n.tc("label.lbl_opeHist"),
        value: "opeHist",
        width: "180px",
        align: "left",
        sortable: false,
      },
      // 検査担当者
      {
        text: i18n.tc("label.lbl_inspector"),
        value: "inspector",
        width: "250px",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 検査項目内容
      {
        text: i18n.tc("label.lbl_contentsOfInspectionItems"),
        value: "contentsOfInspectionItems",
        width: "200px",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 検査項目
      {
        text: i18n.tc("label.lbl_inspectionItems"),
        value: "inspectionItems",
        width: "180px",
        align: "left",
        sortable: false,
      },
      // 検査内容
      {
        text: i18n.tc("label.lbl_contentsOfInspection"),
        value: "contentsOfInspection",
        width: "150px",
        align: "left",
        sortable: false,
      },
      // 詳細内容入力
      {
        text: i18n.tc("label.lbl_inputDetail"),
        value: "inputDetail",
        width: "200px",
        align: "center",
        sortable: false,
        class: "asta",
      },
      // 入荷実績日
      {
        text: i18n.tc("label.lbl_arrivalacheivementDate"),
        value: "enteringAchieveDate",
        width: "200px",
        align: "left",
        sortable: false,
      },
      // 発見場所
      {
        text: i18n.tc("label.lbl_locationOfDiscovery"),
        value: "locationOfDiscovery",
        width: "400px",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 路線業者
      {
        text: i18n.tc("label.lbl_routeOperator"),
        value: "routeOperator",
        width: "200px",
        align: "left",
        sortable: false,
      },
      // 便名
      {
        text: i18n.tc("label.lbl_flightNumber"),
        value: "flightNumber",
        width: "400px",
        align: "left",
        sortable: false,
      },
      // 記入日
      {
        text: i18n.tc("label.lbl_dateEntered"),
        value: "dateEntered",
        width: "200px",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // 記入者
      {
        text: i18n.tc("label.lbl_enteryPerson"),
        value: "enteryPerson",
        width: "250px",
        align: "left",
        sortable: false,
        class: "asta",
      },
      // アップロード
      {
        text: i18n.tc("btn.btn_importConfirm"),
        value: "upload",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 報告書出力
      {
        text: i18n.tc("label.lbl_reportOutput"),
        value: "reportOutput",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // ダウンロード
      {
        text: i18n.tc("label.lbl_downloadFile"),
        value: "download",
        width: "100px",
        align: "center",
        sortable: false,
      },
      // 削除
      {
        text: i18n.tc("label.lbl_delete"),
        value: "delete",
        width: "5%",
        align: "center",
        sortable: false,
      },
    ],
    detailDialogData: {},
    inputList: [],
    defaultItem: {},
    apiReqParam: {
      clientSid: "",
      utcDate: "",
    },
    rules: {
      yyyymmdd: (value) =>
        value == null ||
        value == "" ||
        dateTimeHelper.validDate(commonUtil.zen2han(value), "/") ||
        i18n.tc("check.chk_inputDate"),
      supplierRequired: (value) =>
        !!value || i18n.tc("label.lbl_supplier") + i18n.tc("check.chk_input_variable"),
      bussinessNoRequired: (value) =>
        !!value || i18n.tc("label.lbl_billingBussinessNo") + i18n.tc("check.chk_input_variable"),
      bussinessNameRequired: (value) =>
        !!value || i18n.tc("label.lbl_bussinessName") + i18n.tc("check.chk_input_variable"),
      shipperCutOffDateRequired: (value) =>
        !!value || i18n.tc("label.lbl_shipperCutOffDate") + i18n.tc("check.chk_input_variable"),
      paymentDateRequired: (value) =>
        !!value || i18n.tc("label.lbl_paymentDate") + i18n.tc("check.chk_input_variable"),
      required: (value) => !!value || i18n.tc("check.chk_input"),
    },
    headerProductCode: "",
    headerProductCodeShow: "",
    headerProductCodeSub: "",
    headerProductName: "",
    headerQualityDiv: "",
    headerLotNo: "",
    headerSerialNo: "",
    headerDueDate: "",
    headerLotSubkey1: "",
    headerLotSubkey2: "",
    headerQuantity: "",
    headerManagementNo: "",
    opeHistList: [],
    inspectorList: [],
    enteryPersonList: [],
    contentsOfInspectionItemsList: [],
    deliveryTraderList: [],
    screenTransitionParameter: {},
    screenTransitionHeaderParameter: {},
    isOpenDetailInputDialog: false,
    isOpenFileDownloadDialog: false,
    isOpenFileUploadDialog: false,
    isOpenDeleteDialog: false,
    editItem: null,
    changeFlg: false,
    ConfirmDialog: {
      message: "",
      redMessage: "",
      okAction: () => {},
      isOpen: false,
    },
    itemStandardSid: "",
    qualityDiv: "",
    inActDate: "",
    itemManageNoOrg: "",
    inListSubSid: "",
    outListSubSid: "",
  }),
  methods: {
    // 初期化
    init() {
      this.inputList = [];
      this.deleteItem = [];
      let param = this.$route.params.selectData;
      if (param != null) {
        this.screenTransitionParameter = param;
        this.screenTransitionHeaderParameter = this.$route.params.headerData;
        this.headerProductCode = param.productCodeShow.substring(0, 50).trimRight();
        this.headerProductCodeShow = param.productCodeShow;
        this.headerProductCodeSub = param.productCodeShow.slice(-50).trimRight();
        this.headerProductName = param.productName;
        this.headerQualityDiv = param.qualityDivName;
        let lotNoObj = commonUtil.convertLotNo(param.lotNoShow);
        this.headerLotNo = lotNoObj.original.lot;
        this.headerSerialNo = lotNoObj.original.serial;
        this.headerDueDate = lotNoObj.original.dueDate;
        this.headerLotSubkey1 = lotNoObj.original.lotSubkey1;
        this.headerLotSubkey2 = lotNoObj.original.lotSubkey2;
        this.headerQuantity = param.quantity;
        this.headerManagementNo = param.managementNo;
        this.itemStandardSid = param.itemStandardSid;
        this.qualityDiv = param.qualityDiv;
        this.inActDate = param.inActDate;
        this.itemManageNoOrg = param.itemManageNoOrg;
        this.inListSubSid = param.inListSubSid;
        this.outListSubSid = param.outListSubSid;
      }
      this.suppliersSelected = this.$route.params.headerData.clientSid;
      this.getApi();
      this.getMaster();
    },
    // ドロップダウンAPI接続
    getMaster() {
      this.getOpeList(); // 工程取得
      this.getDeliveryTrader(); // 路線取得
      this.getInspector(); // 担当者取得
      this.getContentsOfInspectionItemsList(); // 検査項目内容取得
    },
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action = () => {};
      switch (button) {
        case "btn_insert":
          var cnt = 0;
          if (this.$refs.inputList.validate()) {
            // 空行を除く
            let target = this.inputList.filter((value) => {
              return !!value.dateOfInspection;
            });
            this.inputList = target;
            let deleteTarget = this.deleteItem.filter((value) => {
              return !!value.inspectionSid;
            });
            this.inputList = target;
            this.deleteItem = deleteTarget;
            // 詳細内容入力チェック
            for (var i = 0; i < this.inputList.length; i++) {
              if (this.inputList[i].inputDetail.length <= 0) {
                cnt = cnt + 1;
              }
              if (cnt > 0) {
                this.updateDialogMessage = true;
                this.backMessage = messsageUtil.getMessage("P-WST-001_005_E");
                return;
              }
            }
          } else {
            // 必須項目入力がない場合、入力エラー
            return;
          }
          message = messsageUtil.getMessage("P-RCV-001_003_C");
          action = this.postApi;
          break;
        case "btn_back":
          isValidationRequired = false;
          message = messsageUtil.getMessage("P-COM-001_004_W");
          action = this.backPage;
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        // let isValid = this.$refs.editedList.validate();
        // if (!isValid) {
        //   return;
        // }
      }
      this.ConfirmDialog.message = message;
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = action;
    },
    // 実績一覧取得
    getApi() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // リクエストパラメータを設定
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.itemManageNo = this.headerManagementNo; // 管理番号
      config.params.qualityDiv = this.$route.params.selectData.qualityDiv; // 品質区分
      if (this.$route.params.selectData.inListSubSid) {
        config.params.inListSubSid = this.$route.params.selectData.inListSubSid; // 入荷伝票明細SID
      }
      if (this.$route.params.selectData.outListSubSid) {
        config.params.outListSubSid = this.$route.params.selectData.outListSubSid; // 出荷伝票明細SID
      }

      // console.debug("getApi() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DEFECTIVE_DISCARDED_DETAILS_GET, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getApi() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // ページング
              const list = [];
              jsonData.resIdv.defectiveContents.forEach((row) => {
                list.push({
                  inspectionSid: row.testSid, // 検査SID
                  dateOfInspection: row.yyyymmddHhmmss
                    ? dateTimeHelper.convertUTC2JST(row.yyyymmddHhmmss).substr(0, 10)
                    : null, // 検査実績日
                  dateOfInspectionCal: row.yyyymmddHhmmss
                    ? dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(row.yyyymmddHhmmss))
                    : null, // 検査実績日
                  dateOfInspectionMenu: false,
                  opeHist: row.procCd, // 工程
                  inspector: row.testUserId, // 検査担当者
                  contentsOfInspectionItems: row.testItemDetailSid, // 検査項目内容
                  inspectionItems: row.testItemName, // 検査項目
                  contentsOfInspection: row.testDetailName, // 検査内容
                  inputDetail: row.badQualityComment, // 不良詳細
                  enteringAchieveDate: row.inActDate
                    ? dateTimeHelper.convertUTC2JST(row.inActDate).substr(0, 10)
                    : null, // 入庫実績日
                  inActDate: row.inActDate, // 入庫実績日
                  locationOfDiscovery: row.foundPlace, // 発見場所
                  routeOperator: row.deliveryTraderSid, // 路線業者
                  flightNumber: row.binNo, // 便名
                  dateEntered: row.fillInDate
                    ? dateTimeHelper.convertUTC2JST(row.fillInDate).substr(0, 10)
                    : null, // 記入日
                  fillInDate: row.fillInDate, // 記入日
                  dateEnteredCal: row.fillInDate
                    ? dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(row.fillInDate))
                    : null, // 記入日
                  dateEnteredMenu: false,
                  enteryPerson: row.fillInUserSid, // 記入者
                  badQualitySid: row.badQualitySid, // 不良Sid
                  savePath: row.savePath, // 保存パス
                  reportGetDiv: row.reportGetDiv, // 受領区分
                  testHistUpdateDatetime: row.testHistUpdateDatetime, // 検査実績更新日付
                  badQualityHistupdateDatetime: row.badQualityHistupdateDatetime, // 不要実績更新日付
                });
              });
              this.inputList = list;
              this.deleteItem = [];
              resolve(list);
              // エラー時
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((resolve) => {
            this.infoDialog.message = resolve;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // 実績登録
    postApi() {
      // ローディング画面を表示します。
      this.loadingCounter = 1;

      // プルダウンの値を変更して詰める必要があるため新しいリストを作る
      var list = [];
      // 削除リスト作成
      for (let i = 0; i < this.deleteItem.length; i++) {
        var deleteMap = {};

        deleteMap.physicalDeleteflg = "1"; // 削除フラグ
        deleteMap.savePath = this.deleteItem[i].savePath; // ファイルバス
        deleteMap.testSid = this.deleteItem[i].inspectionSid
          ? this.deleteItem[i].inspectionSid
          : null; // 検査SID
        deleteMap.testHistUpdateDatetime = this.deleteItem[i].testHistUpdateDatetime
          ? this.deleteItem[i].testHistUpdateDatetime
          : null; // 検査実績更新日付
        deleteMap.badQualityHistupdateDatetime = this.deleteItem[i].badQualityHistupdateDatetime
          ? this.deleteItem[i].badQualityHistupdateDatetime
          : null; // 不要実績更新日付
        deleteMap.badQualitySid = this.deleteItem[i].badQualitySid
          ? this.deleteItem[i].badQualitySid
          : null; // 不良SID
        list.push(deleteMap);
      }
      // 登録・更新リスト作成

      for (let i = 0; i < this.inputList.length; i++) {
        var inputMap = {};

        inputMap.yyyymmddHhmmss = dateTimeHelper.convertUTC(this.inputList[i].dateOfInspection); // 日時
        inputMap.testSid = this.inputList[i].inspectionSid ? this.inputList[i].inspectionSid : null; // 検査SID
        inputMap.testItemDetailSid = this.inputList[i].contentsOfInspectionItems; // 検査項目内容SID
        inputMap.procCd = this.inputList[i].opeHist ? this.inputList[i].opeHist : null; // 工程CD
        inputMap.testUserId = this.inputList[i].inspector; // 検査担当者SID
        inputMap.badQualitySid = this.inputList[i].badQualitySid
          ? this.inputList[i].badQualitySid
          : null; // 不良SID
        inputMap.badQualityComment = this.inputList[i].inputDetail
          ? this.inputList[i].inputDetail
          : null; // 不良詳細
        inputMap.reportGetDiv = this.inputList[i].reportGetDiv
          ? this.inputList[i].reportGetDiv
          : null; // 受領区分
        inputMap.inActDate = this.inputList[i].inActDate
          ? this.inputList[i].inActDate
          : this.$route.params.selectData.inActDate; // 入荷実績日
        inputMap.foundPlace = this.inputList[i].locationOfDiscovery; // 発見場所
        inputMap.deliveryTraderSid = this.inputList[i].routeOperator
          ? this.inputList[i].routeOperator
          : null; // 路線業者SID
        inputMap.binNo = this.inputList[i].flightNumber ? this.inputList[i].flightNumber : null; // 便No
        inputMap.fillInDate = dateTimeHelper.convertUTC(this.inputList[i].dateEntered); // 記入日
        inputMap.fillInUserSid = this.inputList[i].enteryPerson; // 記入担当者SID
        inputMap.testHistUpdateDatetime = this.inputList[i].testHistUpdateDatetime
          ? this.inputList[i].testHistUpdateDatetime
          : null; // 検査実績更新日付
        inputMap.badQualityHistupdateDatetime = this.inputList[i].badQualityHistupdateDatetime
          ? this.inputList[i].badQualityHistupdateDatetime
          : null; // 不要実績更新日付
        list.push(inputMap);
      }

      const body = this.$httpClient.createRequestBodyConfig();
      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_WST_002;
      body.reqIdv.itemStandardSid = this.$route.params.selectData.itemStandardSid; // 商品標準SID
      body.reqIdv.clientSid = this.$route.params.headerData.clientSid; // 取引先
      body.reqIdv.qualityDiv = this.$route.params.selectData.qualityDiv; // 品質区分
      body.reqIdv.itemManageNo = this.$route.params.selectData.managementNo; // 管理番号
      body.reqIdv.inListSubSid = this.$route.params.selectData.inListSubSid
        ? this.$route.params.selectData.inListSubSid
        : null; // 入荷伝票明細SID
      body.reqIdv.outListSubSid = this.$route.params.selectData.outListSubSid
        ? this.$route.params.selectData.outListSubSid
        : null; // 出荷伝票明細SID
      body.reqIdv.itemManageNoOrg = this.$route.params.selectData.itemManageNoOrg; // 管理No_org

      body.reqIdv.defectiveList = list;
      if (body.reqIdv.defectiveList.length <= 0) {
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-RCV-001_006_E");
        this.loadingCounter = 0;
        return;
      }
      // console.debug("postApi() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            appConfig.API_URL.DEFECTIVE_DISCARDED_DETAILS_REGIT,
            body,
            appConfig.APP_CONFIG
          )
          .then((response) => {
            // console.debug("postApi() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = false;
              this.infoDialog.outsideClickNotCloseFlg = true;
              this.infoDialog.defectiveDiscardedDetailPageFlag = true;
              this.infoDialog.okAction = this.moveDetail;
              this.infoDialog.firstPageFlag = false;
              resolve(response);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.screenFlag = true;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.defectiveDiscardedDetailPageFlag = false;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.screenFlag = true;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面を非表示にします。
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 詳細画面に遷移
     */
    moveDetail() {
      this.getApi();
    },
    /**
     * 報告書出力
     */
    reportOutput(item) {
      if (item.inspectionSid) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        const config = this.$httpClient.createGetApiRequestConfig();

        // 検査SID
        config.params.testSid = item.inspectionSid;
        // 検査項目内容SID
        config.params.testItemDetailSid = item.contentsOfInspectionItems;
        // 品番
        config.params.itemCd = this.headerProductCodeShow;
        // 営業所SID
        config.params.clientSid = this.$route.params.headerData.clientSid;
        // 取引先SID
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        // 在庫
        config.params.stock = this.$route.params.selectData.quantity;

        // console.debug("reportOutput() Body", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.EXCEL_DEFECTREPORT, config)
            .then((response) => {
              // console.debug("reportOutput() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 成功
              if (jsonData.resCom.resComCode == "000") {
                this.returnURL = jsonData.resIdv.filePath;
                this.link();
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    /**
     * ダウンロード
     */
    download(item) {
      const task = this.downloadFileApi(item);
      Promise.all([task]).then((response) => {
        let urlList = response[0];
        if (urlList.length === 0) {
          this.infoDialog.message = messsageUtil.getMessage("P-WST-001_004_E");
          this.infoDialog.title = "結果";
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        } else {
          const firstDownloadFile = new String(urlList[0].url);
          urlList.splice(urlList.indexOf(urlList[0]), 1);
          window.onbeforeunload = function () {
            for (let i = urlList.length - 1; 0 <= i; i--) {
              setTimeout(
                (elem, isLast) => {
                  if (isLast) {
                    setTimeout(() => {
                      this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
                        i18n.tc("label.lbl_downloadFile"),
                      ]);
                      this.infoDialog.title = "結果";
                      this.infoDialog.isOpen = true;
                      this.infoDialog.firstPageFlag = true;
                    }, 100);
                  }
                  location.href = elem;
                },
                300 + parseInt(i + "00"),
                new String(urlList[i].url),
                i === 0
              );
              urlList.splice(urlList.indexOf(urlList[i]), 1);
            }
            return;
          };
          location.href = firstDownloadFile;
        }
      });
    },
    link() {
      location.href = this.returnURL;
    },
    /**
     * ファイルダウンロードAPI実行
     */
    downloadFileApi(item) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.alertMessage = "";
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_WST_002;
      body.reqCom.reqComOfficeSid = sessionStorage.getItem("sales_office_sid"); // 取引先SID
      body.reqIdv.path = item.savePath;
      // console.debug("downloadFileApi(path) Body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.DEFECTIVE_DISCARDED_DOWNLOAD, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("downloadFileApi(path) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              resolve(jsonData.resIdv.urlList);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            this.loadingCounter = 0;
          });
      });
    },
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    // 取引先コードリストボックス取得処理
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    // 運送業者リストボックス取得処理
    setDeliveryTraderName(item) {
      for (var i = 0; i < this.deliveryTraderList.length; i++) {
        if (this.deliveryTraderList[i].value == item.routeOperator) {
          return this.deliveryTraderList[i].text;
        }
      }
    },

    // 工程リストボックス取得処理
    setOpeHist(item) {
      for (var i = 0; i < this.opeHistList.length; i++) {
        if (this.opeHistList[i].value == item.opeHist) {
          return this.opeHistList[i].name;
        }
      }
    },

    // 検査担当者リストボックス取得処理
    setUser(item) {
      for (var i = 0; i < this.inspectorList.length; i++) {
        if (this.inspectorList[i].value == item.inspector) {
          return this.inspectorList[i].text;
        }
      }
    },

    // 記入者リストボックス取得処理
    setEnteryPerson(item) {
      for (var i = 0; i < this.enteryPersonList.length; i++) {
        if (this.enteryPersonList[i].value == item.enteryPerson) {
          return this.enteryPersonList[i].text;
        }
      }
    },

    // 検査担当者リストボックス取得処理
    setContentsOfInspectionItems(item) {
      for (var i = 0; i < this.contentsOfInspectionItemsList.length; i++) {
        if (this.contentsOfInspectionItemsList[i].value == item.contentsOfInspectionItems) {
          return this.contentsOfInspectionItemsList[i].text;
        }
      }
    },

    // 行追加
    addItem() {
      let addItem = [];
      for (let i = 0; i < 10; i++) {
        let defaultItem = {
          inspectionSid: "",
          dateOfInspection: "",
          dateOfInspectionCal: "",
          dateOfInspectionMenu: false,
          opeHist: "",
          inspector: "",
          contentsOfInspectionItems: "",
          inspectionItems: "",
          contentsOfInspection: "",
          inputDetail: "",
          enteringAchieveDate: "",
          locationOfDiscovery: "",
          routeOperator: "",
          flightNumber: "",
          dateEntered: "",
          dateEnteredCal: "",
          dateEnteredMenu: false,
          enteryPerson: "",
        };
        addItem.push(Object.assign({}, defaultItem));
      }
      this.inputList = this.inputList.concat(addItem);
    },
    // 路線業者マスタAPI（路線会社リストボックス）
    getDeliveryTrader() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.clientSid = this.suppliersSelected;
      config.params.isMst = "0";
      config.params.searchCategory = "0";
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_DELIVERY, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getDeliveryTrader() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.deliveryTrader.forEach((row) => {
                list.push({
                  text: row.deliveryTraderLanguage[0].deliveryTraderName,
                  name: row.deliveryTraderLanguage[0].deliveryTraderName,
                  value: row.deliveryTraderLanguage[0].deliveryTraderSid,
                });
              });
              this.deliveryTraderList = list;

              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getDeliveryTrader() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            // this.loadingCounter = 0;
          });
      });
    },
    //工程API接続
    getOpeList() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_INSPECTION_OFFICE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getDeliveryTrader() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.proc.forEach((row) => {
                list.push({
                  text: row.procName, // 工程名
                  value: row.procCd, // 工程コード
                });
              });
              this.opeHistList = list;

              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getDeliveryTrader() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            // this.loadingCounter = 0;
          });
      });
    },
    // 検査項目内容マスタAPI
    getContentsOfInspectionItemsList() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.clientSid = this.$route.params.headerData.clientSid;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_TEST_OFFICE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getDeliveryTrader() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.testOffice.forEach((row) => {
                list.push({
                  value: row.testItemDetailSid, // 検査項目内容SID
                  text: row.testItemName + "、" + row.testDetailName, // 検査項目内容名称
                });
              });
              this.contentsOfInspectionItemsList = list;

              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getDeliveryTrader() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            // this.loadingCounter = 0;
          });
      });
    },
    // 担当者プルダウン
    getInspector() {
      // ローディング画面表示ON
      // this.loadingCounter = 1;

      // 担当者
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.searchCategoryDiv = "0";

      // console.debug("getManager() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_USERBIZ, config)
          .then((response) => {
            // console.debug("getManager() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.users.forEach((row) => {
                list.push({
                  text: row.userName,
                  value: row.userSid,
                });
              });

              // 画面の初期値を設定します。
              // 検査担当者リスト
              this.inspectorList = list;
              // 記入者
              this.enteryPersonList = list;

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            // this.loadingCounter = 0;
          });
      });
    },
    /**
     * 検査項目内容ドロップダウンの変更イベント
     */
    changeContentsOfInspectionItems(item) {
      let contentsOfInspectionItemsTxt = this.setContentsOfInspectionItems(item);
      if (contentsOfInspectionItemsTxt !== void 0) {
        let contentsOfInspectionItemsTxtArray = contentsOfInspectionItemsTxt.split("、");
        item.inspectionItems = contentsOfInspectionItemsTxtArray[0];
        if (1 < contentsOfInspectionItemsTxtArray.length) {
          item.contentsOfInspection = contentsOfInspectionItemsTxtArray[1];
        } else {
          item.contentsOfInspection = "";
        }
      } else {
        item.inspectionItems = "";
        item.contentsOfInspection = "";
      }
    },
    /**
     * 日付をを－１日します。
     */
    prevDate(identifier, item) {
      let target;
      switch (identifier) {
        case "dateOfInspection":
          target = item.dateOfInspectionCal;
          break;
        case "dateEntered":
          target = item.dateEnteredCal;
          break;
        default:
          return;
      }
      if (target == null) {
        return;
      }
      let date = new Date(target);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (target != toDate) {
        target = toDate;
      } else {
        target = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 日付を＋１日します。
     */
    nextDate(identifier, item) {
      let target;
      switch (identifier) {
        case "dateOfInspection":
          target = item.dateOfInspectionCal;
          break;
        case "dateEntered":
          target = item.dateEnteredCal;
          break;
        default:
          return;
      }
      if (target == null) {
        return;
      }
      let date = new Date(target);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (target != toDate) {
        target = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        target = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * 検査実施日付入力
     */
    changeDate(val, item) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        item.dateOfInspectionCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        item.dateOfInspectionCal = null;
      }
    },
    // 入荷実績日を設定
    setInActDate(item) {
      if (item.dateOfInspection) {
        item.enteringAchieveDate = this.$route.params.selectData.inActDate
          ? this.$route.params.selectData.inActDate.substr(0, 10)
          : null;
      }
    },
    /**
     * 行削除
     */
    toDeleteItem() {
      this.deleteDialogMessage = false;
      this.editedIndex = this.inputList.indexOf(this.deleteData);
      this.deleteItem.push(this.inputList[this.editedIndex]);
      this.inputList.splice(this.editedIndex, 1);
      this.deleteData = {};
    },
    /**
     * 前画面に戻る
     */
    backPage() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_WST_001,
        params: {
          selectData: this.screenTransitionParameter,
          headerData: this.screenTransitionHeaderParameter,
        },
      });
    },
    /**
     * 不良、廃棄内容登録ダイアログを表示
     */
    openDetailInputDialog(item) {
      this.editItem = item;
      this.isOpenDetailInputDialog = true;
    },
    /**
     * ファイルダウンロードダイアログを表示
     */
    openFileDownloadDialog(item) {
      this.editItem = item;
      this.isOpenFileDownloadDialog = true;
    },
    /**
     * アップロードボタン押下チェック
     */
    checkBeforOpen(item) {
      var cnt = 0;
      if (item.dateOfInspection == null || item.dateOfInspection == "") {
        cnt = cnt + 1;
      } else if (item.dateOfInspection != this.formatDate(item.dateOfInspection)) {
        cnt = cnt + 1;
      }
      if (item.inspector == null || item.inspector == "") {
        cnt = cnt + 1;
      }
      if (item.contentsOfInspectionItems == null || item.contentsOfInspectionItems == "") {
        cnt = cnt + 1;
      }
      if (item.locationOfDiscovery == null || item.locationOfDiscovery == "") {
        cnt = cnt + 1;
      }
      if (item.dateEntered == null || item.dateEntered == "") {
        cnt = cnt + 1;
      } else if (item.dateEntered != this.formatDate(item.dateEntered)) {
        cnt = cnt + 1;
      }
      if (item.enteryPerson == null || item.enteryPerson == "") {
        cnt = cnt + 1;
      }
      if (item.inputDetail.length <= 0) {
        cnt = cnt + 1;
      }

      // エラーをカウント
      this.errorChecks = cnt;
    },
    /**
     * ファイルアップロードダイアログを表示
     */
    openFileUploadDialog(item) {
      // 入力チェック
      this.checkBeforOpen(item);
      // 未入力がある場合
      if (this.errorChecks > 0) {
        this.updateDialogMessage = true;
        this.backMessage = "未入力項目があります";
        return;
      } else {
        // 未入力なし
        this.editItem = item;
        this.isOpenFileUploadDialog = true;
      }
      // バリエーションチェック
      // let dateOfInspectionCheck = eval("this.$refs.dateOfInspection" + index + ".validate()"); // 検査実績日
      // let inspectorCheck = eval("this.$refs.inspector" + index + ".validate()"); // 検査担当者
      // let contentsOfInspectionItemsCheck = eval(
      //   "this.$refs.contentsOfInspectionItems" + index + ".validate()"
      // ); // 検査項目内容
      // let locationOfDiscoveryCheck = eval("this.$refs.locationOfDiscovery" + index + ".validate()"); // 発見場所
      // let dateEnteredCheck = eval("this.$refs.dateEntered" + index + ".validate()"); // 記入日
      // let enteryPersonCheck = eval("this.$refs.enteryPerson" + index + ".validate()"); // 記入者
      // if (
      //   dateOfInspectionCheck &&
      //   inspectorCheck &&
      //   contentsOfInspectionItemsCheck &&
      //   locationOfDiscoveryCheck &&
      //   dateEnteredCheck &&
      //   enteryPersonCheck
      // ) {
      //   if (item.inputDetail.length <= 0) {
      //     this.infoDialog.message = messsageUtil.getMessage("P-WST-001_005_E");
      //     this.infoDialog.title = appConfig.DIALOG.title;
      //     this.infoDialog.isOpen = true;
      //     this.infoDialog.firstPageFlag = true;
      //     this.infoDialog.defectiveDiscardedDetailPageFlag = false;
      //   } else {
      //     this.editItem = item;
      //     this.isOpenFileUploadDialog = true;
      //   }
      // } else {
      //   return;
      // }
    },
    /**
     * 実績削除ダイアログを表示
     */
    openDeleteDialog(item) {
      // すでに登録されたデータを削除
      if (item.inspectionSid) {
        this.editItem = item;
        this.isOpenDeleteDialog = true;
      } else {
        // 行追加された後の削除
        this.deleteData = item;
        var cnt = 0;
        if (item.dateOfInspection) {
          cnt = cnt + 1;
        }
        if (item.opeHist) {
          cnt = cnt + 1;
        }
        if (item.inspector) {
          cnt = cnt + 1;
        }
        if (item.contentsOfInspectionItems) {
          cnt = cnt + 1;
        }
        if (item.locationOfDiscovery) {
          cnt = cnt + 1;
        }
        if (item.routeOperator) {
          cnt = cnt + 1;
        }
        if (item.flightNumber) {
          cnt = cnt + 1;
        }
        if (item.dateEntered) {
          cnt = cnt + 1;
        }
        if (item.enteryPerson) {
          cnt = cnt + 1;
        }
        if (item.inputDetail.length > 0) {
          cnt = cnt + 1;
        }
        if (cnt > 0) {
          this.deleteDialogMessage = true;
          this.deleteMessage = messsageUtil.getMessage("P-COM-001_004_W");
          return;
        } else {
          this.toDeleteItem();
        }
      }
    },
    /**
     * 本画面と各ダイアログとのデータ受け渡しを行う関数
     */
    parentMethod: async function (operation, item) {
      switch (operation) {
        case "inputDetail":
          return;
        case "deleteRecord":
          this.editedIndex = this.inputList.indexOf(item);
          this.deleteItem.push(this.inputList[this.editedIndex]);
          this.inputList.splice(this.editedIndex, 1);
          return;
        case "deleteFile":
          return;
        case "uploadDialog":
          await this.getApi();
          return;
        default:
          return;
      }
    },
    // OKボタン処理
    updateOk() {
      this.updateDialogMessage = false;
    },
    // キャンセルボタン押下
    cancel() {
      this.deleteDialogMessage = false;
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
  // padding-top: 8px;
  // margin-left: 0;
  // margin-right: 0;
}
.toatlNum {
  margin-left: 10px;
}
.flex {
  display: flex;
}
.scrollType::-webkit-scrollbar {
  display: none;
}

.scrollTypeClose {
  overflow-y: scroll;
}

.table-scroll-host {
  grid-area: content;
  overflow: scroll;
}
.text-overflow {
  // v-tooltip
  width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// テーブルフッターの高さ調整
// .minheight {
//   margin-bottom: 0px;
// }
</style>
