<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <Loading />
    <!-- 検索項目 -->
    <v-card class="mx-auto" style="height: 600px">
      <v-card-title>
        <p style="background: linear-gradient(transparent 50%, #ffff00 95%)">
          {{ $t("label.lbl_defectiveDiscardedFileDownload") }}
        </p>
      </v-card-title>
      <v-card-subtitle> </v-card-subtitle>
      <!-- 詳細入力 -->
      <div style="display: flex; justify-content: center; height: 450px">
        <v-form ref="detailInput">
          <v-container fluid>
            <div>
              <div style="width: 1000px; float: left">
                <!-- データテーブル -->
                <v-data-table
                  id="listData"
                  fixed-header
                  :headers="headerItems"
                  :items="downloadFileList"
                  disable-filtering
                  disable-pagination
                  disable-sort
                  :hide-default-footer="true"
                  height="400px"
                  class="elevation-1"
                >
                  <!-- ダウンロードリンク -->
                  <template v-slot:[`item.name`]="{ item }">
                    <a @click="checkLinkAvailable(item)">
                      {{ item.name }}
                    </a>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-container>
        </v-form>
      </div>
      <v-row style="float: right">
        <v-card-actions>
          <v-col>
            <!-- 閉じるボタン -->
            <v-btn
              ref="closeBtn"
              color="primary"
              id="closeBtn"
              class="other-btn"
              right
              @click="close()"
              >{{ $t("btn.btn_close") }}</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-row>
    </v-card>

    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />

    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :screenFlag="infoDialog.screenFlag"
      :changeFlag="infoDialog.changeFlag"
      :firstPageFlag="infoDialog.firstPageFlag"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import { i18n } from "../../lang/lang.js";
import axios from "axios";
export default {
  name: "DefectiveDiscardedFileDownloadDialog",
  components: {
    Loading,
    ConfirmDialog,
    SimpleDialog,
  },
  // 親画面から渡される値
  props: {
    editItem: { type: Object, default: null },
    isOpenFileDownloadDialog: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    downloadFileList: [],
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    rules: {},
    // ヘッダ
    headerItems: [
      // アップロードファイル
      {
        text: i18n.tc("label.lbl_downloadFileList"),
        value: "name",
        width: "5%",
        align: "left",
        sortable: false,
      },
    ],
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      outsideClickNotCloseFlg: false,
      infoDialog: false,
    },
  }),

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      if ((this.editItem.savePath ?? "") === "") {
        this.infoDialog.message = messsageUtil.getMessage("P-WST-001_004_E");
        this.infoDialog.title = "結果";
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        return;
      }
      this.loadingCounter = "1";
      this.downloadFileList = [];
      this.createDownloadLink(this.editItem)
        .then(() => {})
        .catch(() => {
          this.loadingCounter = 0;
        })
        .finally(() => {
          this.loadingCounter = 0;
        });
    },
    /**
     * 閉じる処理
     */
    close() {
      this.$emit("update:isOpenFileDownloadDialog", false);
    },
    /**
     * ダウンロード
     */
    createDownloadLink(item) {
      const task = this.downloadFileApi(item);
      return Promise.all([task]).then((response) => {
        let urlList = response[0];
        if (urlList.length === 0) {
          this.infoDialog.message = messsageUtil.getMessage("P-WST-001_004_E");
          this.infoDialog.title = "結果";
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        } else {
          this.downloadFileList = urlList.map((obj) => {
            return { url: obj.url, name: obj.fileName };
          });
        }
      });
    },
    /**
     * ファイルダウンロードAPI実行
     */
    downloadFileApi(item) {
      this.alertMessage = "";
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_WST_002;
      body.reqCom.reqComOfficeSid = sessionStorage.getItem("sales_office_sid"); // 取引先SID
      body.reqIdv.path = item.savePath;
      // console.debug("downloadFileApi(path) Body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.DEFECTIVE_DISCARDED_DOWNLOAD, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("downloadFileApi(path) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              resolve(jsonData.resIdv.urlList);
              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {});
      });
    },
    /**
     * 署名付きURLが有効か確認する
     */
    async checkLinkAvailable(item) {
      this.loadingCounter = "1";
      await axios
        .get(item.url)
        .then(() => {
          location.href = item.url;
        })
        .catch((ex) => {
          console.error(ex);
          this.infoDialog.message = messsageUtil.getMessage("P-WST-001_006_E");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          this.loadingCounter = "0";
        });
    },
    reload() {},
  },
  computed: {},
  watch: {
    isOpenFileDownloadDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.text-popBtn {
  padding-bottom: 30px;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}
.v-data-table th {
  background: #dcdcdc;
}
.v-data-table td {
  background: white;
}
.v-data-table tr:nth-child(odd) td {
  background: white;
}
</style>
