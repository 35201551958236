<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <Loading />
    <!-- 検索項目 -->
    <v-card class="mx-auto">
      <v-card-title>
        <p style="background: linear-gradient(transparent 50%, #ffff00 95%)">
          {{ $t("label.lbl_defectiveDiscardedDelete") }}
        </p>
      </v-card-title>
      <v-card-subtitle> </v-card-subtitle>
      <v-card-text>
        <!-- 詳細入力 -->
        <v-form ref="detailInput" lazy-validation>
          <v-container fluid>
            <!-- 削除パターン-->
            <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
              <v-autocomplete
                dense
                v-model="deletePattern"
                :items="deletePatternList"
                :label="$t('label.lbl_deletePattern')"
                class="txt-single"
                persistent-hint
                :rules="[rules.required]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer" style="float: left">*</span>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col>
          <div style="float: right">
            <!-- 閉じるボタン -->
            <v-btn
              ref="closeBtn"
              color="primary"
              id="closeBtn"
              class="other-btn"
              right
              @click="viewConfirmDialog('btn_close')"
              >{{ $t("btn.btn_close") }}</v-btn
            >
            <!-- 削除ボタン -->
            <v-btn
              color="primary"
              id="btn-content-config"
              class="other-btn"
              @click="viewConfirmDialog('btn_delete')"
              >{{ $t("btn.btn_delete") }}</v-btn
            >
          </div>
        </v-col>
      </v-card-actions>
    </v-card>

    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />

    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :screenFlag="infoDialog.screenFlag"
      :changeFlag="infoDialog.changeFlag"
      :firstPageFlag="infoDialog.firstPageFlag"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import { i18n } from "../../lang/lang.js";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import axios from "axios";
export default {
  name: "DefectiveDiscardedDetailInputDialog",
  components: {
    Loading,
    ConfirmDialog,
    SimpleDialog,
  },
  // 親画面から渡される値
  props: {
    editItem: { type: Object, default: null },
    isOpenDeleteDialog: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 削除パターン
    deletePattern: "",
    // 削除パターン一覧
    deletePatternList: [],
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    rules: {
      required: function (value) {
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          value = value.replace(/\s+/g, "");
          if (value === "") {
            return i18n.tc("check.chk_input");
          }
        } else {
          return i18n.tc("check.chk_input");
        }
        return true;
      },
    },
  }),

  methods: {
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action;
      switch (button) {
        case "btn_delete":
          isValidationRequired = true;
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_delete")]);
          action = this.delete;
          break;
        case "btn_close":
          this.close();
          return;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        let isValid = this.$refs.detailInput.validate();
        if (!isValid) {
          return;
        }
      }
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.deletePattern = "";
      this.$refs.detailInput.resetValidation();
      // 削除パターンドロップダウン要素を取得
      this.getMstCode("1104");
    },

    /**
     * 閉じる処理
     */
    close() {
      this.$emit("update:isOpenDeleteDialog", false);
    },

    /**
     * 削除処理
     */
    async delete() {
      if (this.deletePattern === "01") {
        this.$emit("child-event", "deleteRecord", this.editItem);
      } else if (this.deletePattern === "02") {
        await this.deleteFileApi(this.editItem);
        this.$emit("child-event", "deleteFile", this.editItem);
      }
      this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [
        i18n.tc("btn.btn_delete"),
      ]);
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      this.infoDialog.itemsMstFlg = true;
      this.infoDialog.firstPageFlag = true;
      this.close();
    },
    /**
     * ファイル削除API実行
     */
    deleteFileApi(item) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.alertMessage = "";
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_WST_002; // 画面ID
      body.reqCom.reqComOfficeSid = sessionStorage.getItem("sales_office_sid"); // 取引先SID
      body.reqIdv.path = item.savePath;
      body.reqIdv.procDiv = "DELETE";
      // console.debug("deleteFileApi(path) Body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.DEFECTIVE_DISCARDED_UPLOAD, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("deleteFileApi(path) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              Promise.all([...this.fileDelete(jsonData.resIdv.urlList)])
                .then(async () => {
                  await this.updateReportGetDiv(item);
                  this.loadingCounter = 0;
                  resolve();
                })
                .catch((ex) => {
                  this.loadingCounter = 0;
                  console.error(ex);
                  this.infoDialog.message = messsageUtil.getMessage("P-WST-001_003_E");
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                });
              // エラー時
            } else {
              this.loadingCounter = 0;
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.loadingCounter = 0;
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },
    /**
     * アップロードファイルのみ削除：受領区分を更新API実行
     */
    updateReportGetDiv(item) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_WST_002; // 画面ID
      body.reqIdv.initReportGetDiv = {
        badQualitySid: item.badQualitySid, // 不良SID
        badQualityHistupdateDatetime: item.badQualityHistupdateDatetime, // 不良実績更新日付
      };
      // console.debug("updateReportGetDiv(item) Body", body);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.DEFECTIVE_REPORT_GET_DIV_UPDATE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("updateReportGetDiv(item) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              item.badQualityHistupdateDatetime = jsonData.resIdv.badQualityHistupdateDatetime; // 不要実績更新日付
              item.reportGetDiv = ""; // 受領区分
              resolve();
              // エラー時
            } else {
              this.loadingCounter = 0;
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.loadingCounter = 0;
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          });
      });
    },
    /**
     * 署名付きURLを使用してファイルを削除する
     */
    fileDelete(targets) {
      return targets.map((element) => {
        return axios
          .delete(element.url)
          .then((response) => {
            console.log(response);
            return response;
          })
          .catch((ex) => {
            throw ex;
          });
      });
    },
    // コードマスタ取得
    getMstCode(identifier) {
      this.loadingCounter = 1;
      const codeType = getParameter.getCodeMst(identifier);
      return Promise.all([codeType])
        .then((result) => {
          switch (identifier) {
            case "1104":
              this.deletePatternList = result[0];
              break;
            default:
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          this.loadingCounter = 0;
        });
    },
    reload() {},
  },
  computed: {},
  watch: {
    isOpenDeleteDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.text-popBtn {
  padding-bottom: 30px;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

// ツールチップ ...(省略)設定
.text-overflow {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-row {
  pointer-events: none;
}

.search-autocomplete-product {
  width: 24rem;
}

.calender-component {
  margin: 16px 0 0 0;
  height: 3rem;
}
</style>
