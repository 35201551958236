<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_defectiveDiscardedList" />
      <!-- 不良・廃棄内容一覧 -->
      <v-form ref="form">
        <v-container fluid>
          <!--検索項目-->
          <!--1行目：始まり-->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :rules="[rules.supplierRequired]"
                :hint="setSrhSupplier()"
                persistent-hint
              >
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer" style="float: left">*</span>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- ステータス-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="headerStatus"
                :items="statusList"
                :label="$t('label.lbl_status')"
                :hint="setSrhStatus()"
                :rules="[rules.required]"
                class="txt-single"
                persistent-hint
              ></v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 品番 -->
            <div class="search-autocomplete" style="float: left; width: 300px">
              <v-autocomplete
                dense
                v-model="itemCdSelected"
                :items="itemCdList"
                :label="$t('label.lbl_productCode')"
                @change="(event) => changeProductNm(event)"
                :search-input.sync="search"
                :hint="setItemCd()"
                class="txt-single"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- ロット -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="headerLotNo"
                maxlength="20"
                :label="$t('label.lbl_lot')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- S/N -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="headerSerialNo"
                maxlength="32"
                :label="$t('label.lbl_serial')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 品質区分-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="headerQualityDiv"
                :items="qualityDivList"
                :label="$t('label.lbl_qualityDiv')"
                :hint="setSrhQualityDiv()"
                class="txt-single"
                persistent-hint
              ></v-autocomplete>
            </div>

            <!--1行目：終わり-->
          </v-row>
          <!--2行目：始まり-->
          <v-row class="search-row">
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- カレンダー -->
            <!-- 在庫更新日From-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="stockUpdateDateFrom"
                :label="$t('label.lbl_stockUpdateDateFrom')"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="stockUpdateDateFromCal"
                  @input="dateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 在庫更新日To-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="stockUpdateDateTo"
                :label="$t('label.lbl_stockUpdateDateTo')"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              >
              </v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="stockUpdateDateToCal"
                  @input="dateMenuTo = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 期限日 -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="headerDueDate"
                maxlength="8"
                :label="$t('label.lbl_dueDate')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 付属キー１ -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="headerLotSubkey1"
                maxlength="20"
                :label="$t('label.lbl_lotSubkey1')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 付属キー２ -->
            <div class="serch-textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="headerLotSubkey2"
                maxlength="20"
                :label="$t('label.lbl_lotSubkey2')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!--2行目：終わり-->
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン-->
                <v-btn color="primary" class="api-btn" @click="searchGetApi(false)">
                  {{ $t("btn.btn_search") }}
                </v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
            </div>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :server-items-length="itemsPerPage"
            disable-filtering
            :hide-default-footer="true"
            :options.sync="sortOptions"
            height="511px"
          >
            <!-- ヘッダーロット -->
            <template v-slot:[`header.lotNo`]="{ header }">
              <span>
                <div>{{ header.text }}</div>
                <span>
                  {{
                    $t("label.lbl_dueDate") +
                    "／" +
                    $t("label.lbl_lotSubkey1") +
                    "／" +
                    $t("label.lbl_lotSubkey2")
                  }}
                </span>
              </span>
            </template>
            <!--詳細ボタン-->
            <template v-slot:[`item.detail`]="{ item }">
              <v-btn small @click="jumpTo(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <!-- 品番/付帯品番 -->
            <template v-slot:[`item.productCode`]="{ item }">
              <div v-if="item.productCode.length > 10">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                      {{ item.productCode }}<br />
                      {{ item.accessoryProductCd }}
                    </div>
                  </template>
                  <div>{{ item.productCode }}<br />{{ item.accessoryProductCd }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                {{ item.productCode }}<br />
                {{ item.accessoryProductCd }}
              </div>
            </template>
            <!-- ロット/S/N -->
            <template v-slot:[`item.lotNo`]="{ item }">
              <div v-if="item.lotNo.length > 15 || item.dueDate.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="width: 280px" v-bind="attrs" v-on="on">
                      {{ item.lotNo }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <div>{{ item.lotNo }}<br />{{ item.dueDate }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                {{ item.lotNo }}<br />
                {{ item.dueDate }}
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </div>
        </v-container>

        <v-navigation-drawer absolute temporary v-model="openMenu">
          <sideMenu></sideMenu>
        </v-navigation-drawer>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
        />
        <ConfirmDialog
          :isShow.sync="confirmDialog.isOpen"
          :message="confirmDialog.message"
          :screenFlag="confirmDialog.screenFlag"
          :okAction="confirmDialog.okAction"
          :redMessage="confirmDialog.redMessage"
          :changeFlag="confirmDialog.changeFlag"
        />
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.MENU_ID.P_BIL_CONTRACT_LIST,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    singleSelect: false,
    pListPopup: false,
    alertMessage: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    // メニュー
    openMenu: null,
    // 入荷予定日付カレンダー
    dateMenu: false,
    dateMenu2: false,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    sortOptions: {},
    sortItem: "",
    // 総件数
    totalCount: 0,
    // ロット
    headerLotNo: "",
    //S/N
    headerSerialNo: "",
    //期限日
    headerDueDate: "",
    //付属キー１
    headerLotSubkey1: "",
    //付属キー２
    headerLotSubkey2: "",
    // 品質区分
    headerQualityDiv: "",
    // 品質区分一覧
    qualityDivList: [],
    // ヘッダ品番用：
    search: "",
    itemCdSelected: "",
    itemCdList: [],
    itemCdPara: "",
    itemCd: "",
    itemNamePara: "",
    // ステータス
    headerStatus: "",
    statusList: [],
    // カレンダー
    stockUpdateDateTo: "",
    stockUpdateDateToCal: "",
    stockUpdateDateFrom: "",
    stockUpdateDateFromCal: "",
    dateMenuFrom: false,
    dateMenuTo: false,
    // 検索条件保持用
    searchSuppliersSelected: "", // 取引先
    searchHeaderStatus: "", // ステータス
    searchItemCdSelected: "", // 商品Sid
    searchHeaderLotNo: "", // ロット
    searchHeaderSerialNo: "", // S/N
    searchHeaderDueDate: "", // 期限日
    searchHeaderLotSubkey1: "", // 付属キー１
    searchHeaderLotSubkey2: "", // 付属キー２
    searchHeaderQualityDiv: "", // 品質区分
    searchStockUpdateDateFrom: "", // 在庫更新日FROM
    searchStockUpdateDateFromCal: "", // 在庫更新日FROMカレンダー
    searchStockUpdateDateTo: "", // 在庫更新日TO
    searchStockUpdateDateToCal: "", // 在庫更新日TOカレンダー
    searchItemCd: "", // 品番
    searchItemName: "", // 品名
    //初期値(ソート処理用)
    initial: 0,
    itemFlg: false,
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      // 品番
      {
        text: i18n.tc("label.lbl_productCode") + "/" + i18n.tc("label.lbl_accessoryProduct"),
        value: "productCode",
        width: "11%",
        align: "left",
        sortable: false,
      },
      // 品名
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "productName",
        width: "12%",
        align: "left",
        sortable: false,
      },
      // 品質区分
      {
        text: i18n.tc("label.lbl_qualityDiv"),
        value: "qualityDivName",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // ロット/S/N
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lotNo",
        width: "20%",
        align: "left",
        sortable: false,
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "quantity",
        width: "7%",
        align: "right",
        sortable: false,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_detail"),
        value: "detail",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 在庫更新日
      {
        text: i18n.tc("label.lbl_stockUpdateDate"),
        value: "stockUpdateDate",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 記入日
      {
        text: i18n.tc("label.lbl_dateEntered"),
        value: "dateEntered",
        width: "7%",
        align: "left",
        sortable: false,
      },
      // 更新要
      {
        text: i18n.tc("label.lbl_isUpdateNeeded"),
        value: "isUpdateNeeded",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 廃棄証明書
      {
        text: i18n.tc("label.lbl_certificateOfDestruction"),
        value: "certificateOfDestruction",
        width: "8%",
        align: "left",
        sortable: false,
      },
      // 管理No
      {
        text: i18n.tc("label.lbl_managementNo"),
        value: "managementNo",
        width: "10%",
        align: "left",
        sortable: false,
      },
    ],
    detailDialogData: {},
    // 明細一覧
    inputList: [],
    apiReqParam: {
      clientSid: "",
      utcDate: "",
    },
    rules: {
      supplierRequired: (value) =>
        !!value || i18n.tc("label.lbl_supplier") + i18n.tc("check.chk_input_variable"),
      yyyymmdd: (value) =>
        !value ||
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      required: (value) =>
        !!value || i18n.tc("label.lbl_status") + i18n.tc("check.chk_input_variable"),
    },
  }),
  methods: {
    //  テーブルフッターの高さ変更
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getDefectiveDiscardedList();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getDefectiveDiscardedList();
    },
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.inputList = [];
      this.suppliersSelected = "";
      this.stockUpdateDateToCal = null;
      this.stockUpdateDateFromCal = null;
      this.getSupplierList();
      let param = this.$route.params.headerData;
      if (param != null && !this.clearFlg) {
        this.itemFlg = true;
        // 取引先
        this.suppliersSelected = param.clientSid;
        // 商品Sid
        this.itemCdSelected = param.productSid;
        // 検索条件の品番がある場合：遷移先から品番・品名を取得
        if (param.productSid != null && param.productSid != "") {
          this.itemCdPara = param.productCode; // 品番
          this.itemCd = param.productCode; // 品番
          this.itemNamePara = param.productName; // 品名
        }
        // ロット
        this.headerLotNo = param.lotNo;
        //S/N
        this.headerSerialNo = param.serial;
        // 期限日
        this.headerDueDate = param.dueDate;
        // 付属キー１
        this.headerLotSubkey1 = param.lotSubkey1;
        // 付属キー２
        this.headerLotSubkey2 = param.lotSubkey2;
        // 品質区分
        this.headerQualityDiv = param.qualityDiv;
        // ステータス
        this.headerStatus = param.status;
        // 在庫更新日FROM
        this.stockUpdateDateFrom = param.stockUpdateDateFrom;
        // 在庫更新日FROMカレンダー
        this.stockUpdateDateFromCal = param.stockUpdateDateFromCal;
        // 在庫更新日TO
        this.stockUpdateDateTo = param.stockUpdateDateTo;
        // 在庫更新日TOカレンダー
        this.stockUpdateDateToCal = param.stockUpdateDateToCal;
        this.getDefectiveDiscardedList();
      }
    },
    // 検索ボタン
    searchGetApi(isInit) {
      this.alertMessage = "";
      if (this.stockUpdateDateFrom != null && this.stockUpdateDateTo != null) {
        if (
          // FromToの日付、入力チェック
          new Date(this.stockUpdateDateFrom).getTime() > new Date(this.stockUpdateDateTo).getTime()
        ) {
          this.alertMessage = i18n.tc("check.chk_inputFromVali");
          return;
        }
      }

      if (this.$refs.form.validate() || isInit) {
        this.getDefectiveDiscardedList();
      }
    },

    // 取引先API接続
    getSupplierList(jstInScheduleDate) {
      // ローディング画面表示ON
      // this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.DEFECTIVE_QUALITY_DIV);
      // ステータス
      const statusList = getParameter.getCodeMst(appConfig.CODETYPE.DEFECTIVE_STATUS_DIV);

      Promise.all([clientList, qualityList, statusList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          this.qualityDivList = result[1];
          this.statusList = result[2];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          // this.loadingCounter = 0;
        });
    },
    jumpTo(item) {
      if (this.$refs.form.validate()) {
        item.suppliersSelected = this.inputList[0].clientSid;
        let headerData = {
          productSid: this.searchItemCdSelected, // 商品Sid
          productCode: item.productCodeShow, // 品番
          productName: item.productName, // 品名
          qualityDiv: this.searchHeaderQualityDiv, // 品質区分
          lotNo: this.searchHeaderLotNo, // ロット
          serial: this.searchHeaderSerialNo, // S/N
          dueDate: this.searchHeaderDueDate, // 期限日
          lotSubkey1: this.searchHeaderLotSubkey1, // 付属キー１
          lotSubkey2: this.searchHeaderLotSubkey2, // 付属キー２
          clientSid: this.searchSuppliersSelected, // 取引先
          status: this.searchHeaderStatus, // ステータス
          stockUpdateDateFrom: this.searchStockUpdateDateFrom, // 在庫更新日FROM
          stockUpdateDateFromCal: this.searchStockUpdateDateFromCal, // 在庫更新日FROMカレンダー
          stockUpdateDateTo: this.searchStockUpdateDateTo, // 在庫更新日TO
          stockUpdateDateToCal: this.searchStockUpdateDateToCal, // 在庫更新日TOカレンダー
        };
        this.$router.push({
          name: appConfig.SCREEN_ID.P_WST_002,
          params: {
            selectData: item,
            headerData: headerData,
          },
        });
      }
    },

    // 取引先コードリストボックス取得処理
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.headerQualityDiv) {
          return this.supplierList[i].name;
        }
      }
    },
    // 品質区分コードリストボックス取得処理
    setSrhQualityDiv() {
      for (var i = 0; i < this.qualityDivList.length; i++) {
        if (this.qualityDivList[i].value == this.suppliersSelected) {
          return this.qualityDivList[i].name;
        }
      }
    },
    // ステータスコードリストボックス取得処理
    setSrhStatus() {
      for (var i = 0; i < this.statusList.length; i++) {
        if (this.statusList[i].value == this.headerStatus) {
          return this.statusList[i].name;
        }
      }
    },

    /**
     * 不良・廃棄内容取得
     */
    getDefectiveDiscardedList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // リクエストパラメータを設定
      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.clientSid = this.suppliersSelected; // 取引先
      config.params.outCondition = this.headerStatus; // ステータス
      if (this.itemCd != null && this.itemCd != "") {
        config.params.itemCd = this.itemCd; // 品番
        this.$route.params.headerData = null;
      } else if (this.itemCdSelected) {
        config.params.itemCd = this.getItemCd(); // 品番
      }
      if (this.headerLotNo) {
        config.params.lotNo = this.headerLotNo; // ロット
      }
      if (this.headerSerialNo) {
        config.params.serial = this.headerSerialNo; // S/N
      }
      if (this.headerDueDate) {
        config.params.dueDate = this.headerDueDate; // 期限日
      }
      if (this.headerLotSubkey1) {
        config.params.lotSubkey1 = this.headerLotSubkey1; // 付属キー１
      }
      if (this.headerLotSubkey2) {
        config.params.lotSubkey2 = this.headerLotSubkey2; // 付属キー２
      }
      if (this.headerQualityDiv) {
        config.params.qualityDiv = this.headerQualityDiv; // 品質区分
      }
      if (this.stockUpdateDateFrom) {
        config.params.stockUpdDateFrom = dateTimeHelper.convertUTC(this.stockUpdateDateFrom); // 在庫更新日FROM
      }

      if (this.stockUpdateDateTo) {
        var arr = this.stockUpdateDateTo + " 23:59:59.999";
        config.params.stockUpdDateTo = dateTimeHelper.convertUTC(arr); // 在庫更新日TO
      }

      // ページャー処理
      if (this.page >= 2 && this.inputList.length == 0) {
        config.params.reqComPageIndex = "1";
        this.page = 1;
      } else {
        config.params.reqComPageIndex = this.page;
      }
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;
      // ソート
      if (this.sortItem != "") {
        config.params.reqComSortItem = this.sortItem;
      }
      // 検索条件保持用
      this.searchSuppliersSelected = this.suppliersSelected; // 取引先
      this.searchHeaderStatus = this.headerStatus; // ステータス
      this.searchItemCdSelected = this.itemCdSelected; // 品番
      this.searchHeaderLotNo = this.headerLotNo; // ロット
      this.searchHeaderSerialNo = this.headerSerialNo; // S/N
      this.searchHeaderDueDate = this.headerDueDate; // 期限日
      this.searchHeaderLotSubkey1 = this.headerLotSubkey1; // 付属キー１
      this.searchHeaderLotSubkey2 = this.headerLotSubkey2; // 付属キー２
      this.searchHeaderQualityDiv = this.headerQualityDiv; // 品質区分
      this.searchStockUpdateDateFrom = this.stockUpdateDateFrom; // 在庫更新日FROM
      this.searchStockUpdateDateFromCal = this.stockUpdateDateFromCal; // 在庫更新日FROMカレンダー
      this.searchStockUpdateDateTo = this.stockUpdateDateTo; // 在庫更新日TO
      this.searchStockUpdateDateToCal = this.stockUpdateDateToCal; // 在庫更新日TOカレンダー
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.DEFECTIVE_DISCARDED_LIST, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // ページング
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const list = [];
              jsonData.resIdv.defective.forEach((row) => {
                let lotNoObj = commonUtil.convertLotNo(row.lotNo);
                list.push({
                  // 管理番号
                  managementNo: row.itemManageNo,
                  // 品番
                  productCode: row.itemCd.substring(0, 50).trimRight(),
                  productCodeShow: row.itemCd,
                  //付帯品番
                  accessoryProductCd: row.itemCd.slice(-50).trimRight(),
                  // 品名
                  productName: row.itemName,
                  // 商品表示Sid
                  itemStandardSid: row.itemStandardSid,
                  // ロット + S/N
                  lotNo: lotNoObj.lot,
                  lotNoShow: row.lotNo,
                  // 期限日 + 付属キー１ + 付属キー２
                  dueDate: lotNoObj.dueDate,
                  // 品質区分
                  qualityDiv: row.qualityDiv,
                  //  品質区分名
                  qualityDivName: row.qualityDivName,
                  // 符号付数量合計
                  quantity: Number(row.stock),
                  // 受領区分(廃棄証明書)
                  certificateOfDestructionDiv: row.reportGetDiv,
                  // 受領区分名(廃棄証明書)
                  certificateOfDestruction: row.reportGetDivName,
                  // 記入日
                  dateEntered:
                    row.fillInDate == null
                      ? row.fillInDate
                      : dateTimeHelper.convertUTC2JST(row.fillInDate).substr(0, 10),
                  // 在庫更新日
                  stockUpdateDate: dateTimeHelper.convertUTC2JST(row.stockUpdDate).substr(0, 10),
                  // 更新用
                  isUpdateNeeded: row.updateNeeded,
                  // 入荷実績日
                  inActDate: row.inActDate,
                  // 入荷伝票明細SID
                  inListSubSid: row.inListSubSid,
                  // 出荷伝票明細SID
                  outListSubSid: row.outListSubSid,
                  // 管理No_org
                  itemManageNoOrg: row.itemManageNoOrg,
                });
              });
              this.inputList = list;
              resolve(list);
              // エラー時
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
          })
          .catch((resolve) => {
            this.infoDialog.message = resolve;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 在庫更新日付Fromを－１日します。
     */
    prevDate() {
      if (this.stockUpdateDateFromCal == null) {
        return;
      }
      let date = new Date(this.stockUpdateDateFromCal);
      let fromDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.stockUpdateDateFromCal != fromDate) {
        this.stockUpdateDateFromCal = fromDate;
      } else {
        this.stockUpdateDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 在庫更新日付Fromを＋１日します。
     */
    nextDate() {
      if (this.stockUpdateDateFromCal == null) {
        return;
      }
      let date = new Date(this.stockUpdateDateFromCal);
      let fromDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.stockUpdateDateFromCal != fromDate) {
        this.stockUpdateDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.stockUpdateDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * 在庫更新日付Fromを－１日します。
     */
    prevToDate() {
      if (this.stockUpdateDateToCal == null) {
        return;
      }
      let date = new Date(this.stockUpdateDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.stockUpdateDateToCal != toDate) {
        this.stockUpdateDateToCal = toDate;
      } else {
        this.stockUpdateDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 在庫更新日付Toを＋１日します。
     */
    nextToDate() {
      if (this.stockUpdateDateToCal == null) {
        return;
      }
      let date = new Date(this.stockUpdateDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.stockUpdateDateToCal != toDate) {
        this.stockUpdateDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.stockUpdateDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.stockUpdateDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.stockUpdateDateFromCal = null;
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.stockUpdateDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.stockUpdateDateToCal = null;
      }
    },
    /**
     * 検索エリア：品名取得プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.itemCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.itemCdPara = val.code;
        this.search = val.text;
      }
      this.itemCdList = this.itemCdList.filter((v) => v.value == value);
    },
    /**
     * 検索エリア：品番リストセット処理
     */
    setItemCd() {
      for (var i = 0; i < this.itemCdList.length; i++) {
        if (this.itemCdList[i].value == this.itemCdSelected) {
          return this.itemCdList[i].name;
        }
      }
    },
    /**
     * 検索エリア：品番を取得
     */
    getItemCd() {
      for (var i = 0; i < this.itemCdList.length; i++) {
        if (this.itemCdList[i].value == this.itemCdSelected) {
          return this.itemCdList[i].code;
        }
      }
    },
    // 検索エリア：変更後品番・品名
    itemGetApi(val) {
      if (val != null && val != "") {
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz = getParameter.getItemsBizAddName2(
            this.suppliersSelected,
            dateTimeHelper.convertUTC(),
            val
          );

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              this.itemCdList = list.concat(result[0]);
              this.itemCdList.unshift({ name: "", text: "", value: "", code: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.itemCdList.length = 0;
        }
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // クリアフラグ
      this.clearFlg = true;
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      //画面クリア時の場合
      if (this.clearFlg) {
        return;
      }
      this.page = newValue;
      this.getDefectiveDiscardedList();
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (this.initial == 0) {
          this.sortItem = "";
          this.initial = 1;
        }
        //画面クリア時の場合
        else if (this.clearFlg) {
          this.clearFlg = false;
          return;
        }
        //ソートが解除された場合
        else if (items.sortBy.length < 1) {
          this.sortItem = "";
          this.getDefectiveDiscardedList("");
        }
        //ソート実施時
        else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          if (sortBy == "isUpdateNeeded") {
            sortBy = "update_needed";
          }
          this.sortItem = sortBy + " " + ascdesc;
          this.getDefectiveDiscardedList();
        }
      },
      deep: true,
    },
    stockUpdateDateFromCal(val) {
      this.stockUpdateDateFrom = this.formatDate(val);
      this.alertMessage = "";
    },
    stockUpdateDateToCal(val) {
      this.stockUpdateDateTo = this.formatDate(val);
      this.alertMessage = "";
    },
    search(val) {
      if (val ?? false) {
        if (val == null && this.itemCdPara != null && this.itemCdPara != "") {
          // 商品情報取得API関数呼び出
          this.itemGetApi(this.itemCdPara);
          var itemCd = this.itemCdPara.substring(0, 50).trim();
          var incidental = this.itemCdPara.slice(-50).trim();
          this.productHint = itemCd + " " + incidental + "（" + this.itemNamePara + "）";
          return;
        } else {
          this.itemGetApi(val);
          this.productHint = this.itemCdPara + "（" + this.itemNamePara + "）";
          return;
        }
      }
      if (val == null) {
        if (this.itemCd != null && this.itemFlg == true) {
          this.itemCdPara = this.itemCd;
          this.itemGetApi(this.itemCdPara);
          var itemCd1 = this.itemCdPara.substring(0, 50).trim();
          var incidental1 = this.itemCdPara.slice(-50).trim();
          this.productHint = itemCd1 + " " + incidental1 + "（" + this.itemNamePara + "）";
          this.itemFlg = false;
          this.itemCd = null;
          return;
        }
      }
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
}

.toatlNum {
  margin-left: 10px;
}

#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 8px;
}
</style>
